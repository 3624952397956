import { gql } from '@apollo/client';

export const initialLoginMutation = gql`
  mutation initialLogin($input: InitialLoginInput!) {
    initialLogin(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const sendPasswordResetLinkMutation = gql`
  mutation sendPasswordResetLink($input: SendPasswordResetLinkInput!) {
    sendPasswordResetLink(input: $input) {
      success
    }
  }
`;

export const changePasswordMutation = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      success
      errorMessage
    }
  }
`;

export const sendOtpMutation = gql`
  mutation sendOtp($input: SendOtpInput!) {
    sendOtp(input: $input) {
      success
    }
  }
`;
