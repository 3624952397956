import { BaseSeamlessAuthApi, BaseSeamlessAuthApiSettings } from '@getvim/seamless-auth-api';
import { getAppEnv } from '../common/getAppEnv';

/** Retries by default on status codes that are larger than 500 */
export class SeamlessApiClient extends BaseSeamlessAuthApi {
  constructor(settings: BaseSeamlessAuthApiSettings) {
    super(settings);
  }
}

const environment = getAppEnv();
export const seamlessApiClient = new SeamlessApiClient({
  environment,
});
