import { ApolloError, useMutation } from '@apollo/client';
import { initialLoginMutation } from '../logic/queries';
import {
  Credentials,
  ExpiredPasswordError,
  InternalServerError,
  OtpLoginInput,
  SeamlessCredentials,
} from '../types';
import { AccessToken } from '../logic/types';
import { Login } from '@getvim/vim-connect';
import { useCallback, useState } from 'react';
import { FeatureFlags } from '../logic/feature-flags';
import { seamlessApiClient } from '../api/seamlessApiClient';

enum ErrorType {
  EXPIRED_PASSWORD = 'EXPIRED_PASSWORD',
  INTERNAL_SERVER_ERROR = 'INTERNAL_ERROR',
}

const isPasswordExpired = (error: ApolloError): boolean => {
  const errors = error.graphQLErrors;
  return !!errors.some((e) => e.extensions?.code === ErrorType.EXPIRED_PASSWORD);
};

const isInternalServerError = (error: ApolloError): boolean => {
  const errors = error.graphQLErrors;
  return !!errors.some((e) => e.extensions?.code === ErrorType.INTERNAL_SERVER_ERROR);
};

export const useLogin = (organizationId: number) => {
  const [initialLoginMutate, { loading: initialLoginMutationLoading }] = useMutation(
    initialLoginMutation,
    { context: { uri: '/runtime/api/graphql' } },
  );

  const [isLoading, setIsLoading] = useState(false);

  const login = useCallback(
    async (input: {
      organizationId: number;
      vimCredentials?: Credentials;
      otpLoginInput?: OtpLoginInput;
      seamlessLoginCredentials?: SeamlessCredentials;
      ehrCredentials: Credentials;
    }) => {
      let shouldUseSeamlessAuthApiForLoginFF = false;

      try {
        shouldUseSeamlessAuthApiForLoginFF = await FeatureFlags.getShouldUseSeamlessAuthApiForLogin(
          { organizationId },
        );

        let tokensResult: AccessToken;

        if (shouldUseSeamlessAuthApiForLoginFF) {
          setIsLoading(true);
          tokensResult = await seamlessApiClient.initialLogin(input);
        } else {
          const loginResult = await initialLoginMutate({ variables: { input } });
          tokensResult = loginResult.data?.initialLogin;
        }

        return tokensResult;
      } catch (error) {
        if (shouldUseSeamlessAuthApiForLoginFF) {
          const axiosError = error as {
            response?: { data?: { message?: string }; status?: number };
          };

          const response = axiosError.response;

          if (response?.data?.message === 'Password has expired') {
            throw new ExpiredPasswordError('Password has expired');
          }

          if (response?.status === 500) {
            throw new InternalServerError(Login.INTERNAL_SERVER_ERROR_MESSAGE);
          }
        } else {
          /* NOTE: this condition doesn't seem to happen when using gql, it just go to throw error. The credentials form expects the raw error. 
             The actual code is `VALIDATION_ERROR` instead of `EXPIRED_PASSWORD `*/
          if (isPasswordExpired(error as ApolloError)) {
            throw new ExpiredPasswordError('password expired');
          }

          if (isInternalServerError(error as ApolloError)) {
            throw new InternalServerError(Login.INTERNAL_SERVER_ERROR_MESSAGE);
          }
        }

        throw error;
      } finally {
        if (shouldUseSeamlessAuthApiForLoginFF) {
          setIsLoading(false);
        }
      }
    },
    [initialLoginMutate, organizationId],
  );

  return {
    login,
    loading: initialLoginMutationLoading || isLoading,
  };
};
