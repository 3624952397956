import React, { FC, useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Button, InputStyle } from '@getvim/atomic-ui';
import { testRules } from './rules-logic';
import { PasswordRulesBox } from './PasswordRulesBox';
import { Loader } from '../loader';
import LoginFormRow from '../login-form-row';
import { changePasswordMutation } from '../../logic/queries';
import { AccessToken, LoginResult, UserType } from '../../logic/types';
import { useLogin } from '../../hooks';
import { Credentials } from '../../types';

import '../../resources/styles/password-expired.less';
import { FeatureFlags } from '../../logic/feature-flags';
import { seamlessApiClient } from '../../api/seamlessApiClient';

const GENERIC_ERROR_MSG = 'Could not change the password, please try again';

export interface PasswordExpiredFormProps {
  onSuccess(loginResult: LoginResult): void;
  vimCredentials: Credentials;
  ehrCredentials: Credentials;
  organizationId: number;
}

export const PasswordExpiredForm: FC<PasswordExpiredFormProps> = ({
  onSuccess,
  vimCredentials,
  ehrCredentials,
  organizationId,
}) => {
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [accessToken, setAccessToken] = useState<AccessToken>();
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] = useState<string>();
  const [changePasswordRequest, { loading, error }] = useMutation(changePasswordMutation, {
    errorPolicy: 'all',
    context: {
      uri: '/runtime/api/graphql',
    },
  });
  const { login, loading: loginLoading } = useLogin(organizationId);

  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);

  const handlePasswordChange = useCallback((value: string) => {
    setPassword(value);
  }, []);

  const handleConfirmationPasswordChange = useCallback((value: string) => {
    setConfirmationPassword(value);
  }, []);

  const changePassword = useCallback(async () => {
    try {
      const shouldUseSeamlessAuthApiForLoginFF =
        await FeatureFlags.getShouldUseSeamlessAuthApiForLogin({
          organizationId,
        });

      let changePasswordResult: { success: boolean; errorMessage?: string };
      if (shouldUseSeamlessAuthApiForLoginFF) {
        try {
          setIsChangePasswordLoading(true);
          const changePasswordResponse = await seamlessApiClient.changePassword({
            vimCredentials,
            newPassword: password,
            userType: UserType.VimEhrUser,
            organizationId,
          });

          changePasswordResult = changePasswordResponse ?? {};
        } finally {
          setIsChangePasswordLoading(false);
        }
      } else {
        const result = await changePasswordRequest({
          variables: {
            input: {
              vimCredentials,
              newPassword: password,
              userType: UserType.VimEhrUser,
              organizationId,
            },
          },
        });

        changePasswordResult = result.data.changePassword ?? {};
      }

      const { success, errorMessage } = changePasswordResult;

      if (!success) {
        setChangePasswordErrorMessage(errorMessage);
        return;
      }
      const ehrCreds = { username: ehrCredentials.username, password: ehrCredentials.password };
      const loginResponse = await login({
        organizationId,
        vimCredentials: { ...vimCredentials, password },
        ehrCredentials: ehrCreds,
      });
      setAccessToken(loginResponse);
    } catch {
      setChangePasswordErrorMessage(GENERIC_ERROR_MSG);
    }
  }, [changePasswordRequest, ehrCredentials, login, organizationId, password, vimCredentials]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    onSuccess({ accessToken } as LoginResult);
  }, [onSuccess, accessToken]);

  if (loading || loginLoading || isChangePasswordLoading) {
    return <Loader />;
  }

  const isValidPassword = testRules(password);
  const passwordAndConfirmationMatched = password === confirmationPassword;
  const canSubmit = isValidPassword && passwordAndConfirmationMatched;

  return (
    <div>
      <h1 className="margin-bottom-15">Your password has expired, please set a new password</h1>
      <form name="vim-ehr-login" autoComplete="off">
        <LoginFormRow
          inputStyle={InputStyle.large}
          type="password"
          id="password"
          placeholder="New Password"
          value={password}
          onChanged={handlePasswordChange}
        />
        <LoginFormRow
          inputStyle={InputStyle.large}
          id="confirmation-password"
          placeholder="Re-enter new password"
          type="password"
          value={confirmationPassword}
          onChanged={handleConfirmationPasswordChange}
        />

        <div className="match-validation-message">
          {(changePasswordErrorMessage || error) && (
            <span>• {changePasswordErrorMessage || GENERIC_ERROR_MSG}</span>
          )}

          {!passwordAndConfirmationMatched && confirmationPassword && (
            <span>• Password and confirmation unmatched</span>
          )}
        </div>

        <div className="text-center margin-bottom">
          <Button className="btn-blue" onClick={changePassword} disabled={!canSubmit}>
            Confirm
          </Button>
        </div>
        {!isValidPassword && password && (
          <div className="password-rules-wrap text-center margin-top-30">
            <PasswordRulesBox password={password} />
            <div className="password-strength-error">
              <i className="icon-warning" />
              <div className="password-strength-error-text">Please choose a stronger password</div>
              <i className="icon-chevron-up" />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};
