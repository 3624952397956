import { FlowManager, useFlowContext, useFlowControls } from '@getvim/flow-manager';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { isEmpty } from 'lodash-es';
import CredentialsForm from './CredentialsForm';
import { sendAccessToken, sendLoginFailure, close, sendUserLoginAttempt } from '../logic/messaging';
import {
  AccessToken,
  LoginResult,
  OtpRequestInput,
  SendOtpInput,
  SuccessResponse,
} from '../logic/types';
import {
  ContactDetail,
  ContactDetailType,
  Credentials,
  CredentialsMetadata,
  EhrCredentials,
  LoginStep,
  LoginSteps,
} from '../types';
import { FailureScreen } from './failure-screen';
import { EnterOtpCode } from './otp-login/EnterOtpCode';
import { OtpLoginPage } from './otp-login/OtpLogin';
import { RequestOtpPage } from './otp-login/request-otp';
import { PasswordExpiredForm } from './password-expired/PasswordExpiredForm';
import { PasswordResetForm } from './password-reset/PasswordResetFormNew';
import { SeamlessLoginFailed } from './seamless-login';
import { ErrorPage } from './otp-login/error-page';
import {
  analyticsClient,
  LoginMethods,
  VimConnectOtpLoginAnalyticsEventTypes,
  VimConnectCredentialsLoginAnalyticsEventTypes,
} from '../logic/analytics';
import { BackButton } from '../atomic/atoms/back-button';
import { sendOtpMutation } from '../logic/queries';
import { loginWidgetLogger } from '../logic/logger';
import { Login } from '@getvim/vim-connect';
import { FeatureFlags } from '../logic/feature-flags';
import { seamlessApiClient } from '../api/seamlessApiClient';

interface LoginContext {
  accessToken: string;
  refreshToken: string;
  vimCredentials?: Credentials;
}

interface LoginFlowManagerProps {
  ehrCredentials: EhrCredentials;
  credentialsMetadata: CredentialsMetadata | undefined;
  organizationId: number;
  contactDetails: ContactDetail[];
  isSeamlessLoginFailed?: boolean;
  wasSeamlessLoginShownAndDenied?: boolean;
  goBackToSeamlessLogin: () => void;
}

export const useLoginContext = () => useFlowContext<LoginContext>();
export const useLoginControls = () => useFlowControls<LoginSteps>();

export const LoginFlowManager: FC<LoginFlowManagerProps> = ({
  ehrCredentials: { linkedEhrUsername, ...ehrCredentials },
  credentialsMetadata,
  organizationId,
  contactDetails,
  isSeamlessLoginFailed,
  wasSeamlessLoginShownAndDenied,
  goBackToSeamlessLogin,
}) => {
  const [isOtpFlow] = useState<boolean>(contactDetails.length > 0);

  const [isSendOtpLoading, setIsSendOtpLoading] = useState(false);

  const [sendOtpCode, { loading: sendOtpLoading }] = useMutation<
    { sendOtp: SuccessResponse },
    { input: SendOtpInput }
  >(sendOtpMutation, {
    context: { uri: '/runtime/api/graphql' },
  });

  const [otpRequestCodeAttemptCounter, setOtpRequestCodeAttemptCounter] = useState<number>(0);
  const [otpSendCodeAttemptCounter, setOtpSendCodeAttemptCounter] = useState<number>(0);
  const [otpSubmitCodeAttemptCounter, setOtpSubmitCodeAttemptCounter] = useState<number>(0);
  const [credentialsLoginAttemptCounter, setCredentialsLoginAttemptCounter] = useState<number>(0);

  const alreadySentAccessTokenRef = useRef(false);

  const onRequestOtpCode = async (contactDetailType: ContactDetailType): Promise<boolean> => {
    const input: OtpRequestInput = {
      ehrUser: linkedEhrUsername,
      organizationId,
      contactDetailType,
    };

    const shouldUseSeamlessAuthApiForLoginPart2FF =
      await FeatureFlags.getShouldUseSeamlessAuthApiForLoginPart2({
        organizationIdString: organizationId?.toString(),
      });

    let isSuccess: boolean;

    if (shouldUseSeamlessAuthApiForLoginPart2FF) {
      try {
        // TODO: original implementation doesn't show errors, send otp button is just enabled again
        setIsSendOtpLoading(true);
        const otpResponse = await seamlessApiClient.sendOtp(input);
        isSuccess = otpResponse.success;
      } finally {
        setIsSendOtpLoading(false);
      }
    } else {
      const mutationResult = await sendOtpCode({ variables: { input } });
      isSuccess = (mutationResult?.data as any)?.sendOtp?.success;
    }

    const attemptCounter = otpSendCodeAttemptCounter + 1;
    setOtpSendCodeAttemptCounter(attemptCounter);

    analyticsClient.track(
      VimConnectOtpLoginAnalyticsEventTypes.VimConnectOtpSendCodeButtonClicked,
      {
        method: contactDetailType,
        is_success: isSuccess,
        attempt_number: attemptCounter,
      },
    );

    return isSuccess;
  };

  return (
    <div>
      <FlowManager<LoginContext, LoginSteps>
        initialContext={{}}
        initialStep={isOtpFlow ? LoginStep.OTP_LOGIN : LoginStep.CREDENTIALS_FORM}
        steps={{
          CREDENTIALS_FORM: () => {
            const { updateContext, context } = useLoginContext();
            const { next } = useLoginControls();

            useEffect(() => {
              analyticsClient.track(
                VimConnectOtpLoginAnalyticsEventTypes.VimConnectLoginDisplayed,
                {
                  is_otp_displayed: false,
                },
              );
            }, []);

            const onSuccess = ({ accessToken: { accessToken, refreshToken } }: LoginResult) => {
              updateContext({ accessToken, refreshToken });
              analyticsClient.track(
                VimConnectOtpLoginAnalyticsEventTypes.VimConnectInitialSuccessLogin,
                {
                  login_type: LoginMethods.credentials,
                },
              );
              loginWidgetLogger.warning('Vim Connect initial successful login', {
                login_type: LoginMethods.credentials,
              });
              next(LoginStep.SUCCESS_LOGIN);
            };

            const onFailure = (error: Error) => {
              sendLoginFailure(error);
              next(LoginStep.FAILURE);
            };

            return (
              <>
                {wasSeamlessLoginShownAndDenied && <BackButton onBack={goBackToSeamlessLogin} />}
                <div className={isSeamlessLoginFailed ? '' : 'padding-top-40'}>
                  {isSeamlessLoginFailed || credentialsMetadata?.shouldShowCredentialsWithError ? (
                    <SeamlessLoginFailed />
                  ) : null}
                  <CredentialsForm
                    inputStyle="large"
                    shouldShowTitle
                    ehrCredentials={ehrCredentials}
                    organizationId={organizationId}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    credentialsLoginAttemptCounter={credentialsLoginAttemptCounter}
                    setCredentialsLoginAttemptCounter={setCredentialsLoginAttemptCounter}
                    onPasswordExpired={(vimCredentials) => {
                      updateContext({ ...context, vimCredentials });
                      next(LoginStep.EXPIRED_PASSWORD_CHANGE);
                    }}
                    onPasswordResetRequest={() => {
                      const userHasEmail = contactDetails.find((contactDetail) => {
                        return contactDetail.type === ContactDetailType.email;
                      });
                      analyticsClient.track(
                        VimConnectCredentialsLoginAnalyticsEventTypes.VimConnectCredentialsForgotPasswordButtonClicked,
                        {
                          user_has_email: !isEmpty(userHasEmail),
                        },
                      );

                      next(LoginStep.PASSWORD_RESET_REQUEST_FORM);
                    }}
                    usernamePlaceholder={linkedEhrUsername ?? 'Username'}
                    usernameLabel="Username"
                    passwordPlaceholder="Password"
                    passwordLabel="Vim password"
                  />
                </div>
              </>
            );
          },
          FAILURE: FailureScreen,
          SUCCESS_LOGIN: () => {
            const { context } = useLoginContext();
            const { accessToken, refreshToken } = context;

            useEffect(() => {
              /* TODO: There is bug that cause the LoginFlowManager to render again, ending up the sendAccessToken twice */
              if (alreadySentAccessTokenRef.current) {
                return;
              }

              sendAccessToken(accessToken, refreshToken);
              alreadySentAccessTokenRef.current = true;
              // eslint-disable-next-line react-hooks/exhaustive-deps
            }, []);
            useEffect(() => {
              close();
            }, []);

            return null;
          },
          PASSWORD_RESET_REQUEST_FORM: () => {
            const { prev } = useLoginControls();

            return (
              <PasswordResetForm
                exitForm={prev}
                contactDetails={contactDetails}
                username={linkedEhrUsername}
                organizationId={organizationId}
              />
            );
          },
          EXPIRED_PASSWORD_CHANGE: () => {
            const { updateContext, context } = useLoginContext();
            const { next } = useLoginControls();

            const onSuccess = ({ accessToken: { accessToken, refreshToken } }: LoginResult) => {
              sendUserLoginAttempt(Login.LoginMethodType.CREDENTIALS, { accessToken });
              updateContext({ accessToken, refreshToken });
              next(LoginStep.SUCCESS_LOGIN);
            };

            const { vimCredentials } = context;

            return (
              <PasswordExpiredForm
                vimCredentials={vimCredentials ?? { username: '', password: '' }}
                ehrCredentials={ehrCredentials ?? { username: '', password: '' }}
                organizationId={organizationId}
                onSuccess={onSuccess}
              />
            );
          },
          OTP_LOGIN: () => {
            const { context, updateContext } = useLoginContext();
            const { next } = useLoginControls();

            useEffect(() => {
              analyticsClient.track(
                VimConnectOtpLoginAnalyticsEventTypes.VimConnectLoginDisplayed,
                {
                  is_otp_displayed: true,
                },
              );
            }, []);

            const onCredsSuccess = ({
              accessToken: { accessToken, refreshToken },
            }: LoginResult) => {
              updateContext({ accessToken, refreshToken });
              analyticsClient.track(
                VimConnectOtpLoginAnalyticsEventTypes.VimConnectInitialSuccessLogin,
                {
                  login_type: LoginMethods.credentials,
                },
              );
              loginWidgetLogger.warning('Vim Connect initial successful login', {
                login_type: LoginMethods.credentials,
              });
              next(LoginStep.SUCCESS_LOGIN);
            };

            const onCredsFailure = (error: Error) => {
              sendLoginFailure(error);
              next(LoginStep.FAILURE);
            };

            const onPasswordExpired = (vimCredentials: Credentials) => {
              updateContext({ ...context, vimCredentials });
              next(LoginStep.EXPIRED_PASSWORD_CHANGE);
            };

            const onPasswordReset = () => {
              const userHasEmail = contactDetails.find((contactDetail) => {
                return contactDetail.type === ContactDetailType.email;
              });
              analyticsClient.track(
                VimConnectCredentialsLoginAnalyticsEventTypes.VimConnectCredentialsForgotPasswordButtonClicked,
                {
                  user_has_email: !isEmpty(userHasEmail),
                },
              );
              next(LoginStep.PASSWORD_RESET_REQUEST_FORM);
            };

            const onRequestOtp = () => {
              const attemptCounter = otpRequestCodeAttemptCounter + 1;
              analyticsClient.track(
                VimConnectOtpLoginAnalyticsEventTypes.VimConnectOtpRequestCodeButtonClicked,
                {
                  attempt_number: attemptCounter,
                },
              );
              setOtpRequestCodeAttemptCounter(attemptCounter);
              next(LoginStep.REQUEST_OTP);
            };

            return (
              <OtpLoginPage
                ehrCredentials={ehrCredentials}
                credentialsMetadata={credentialsMetadata}
                ehrUsername={linkedEhrUsername}
                organizationId={organizationId}
                onCredsSuccess={onCredsSuccess}
                onCredsFailure={onCredsFailure}
                onPasswordExpired={onPasswordExpired}
                onPasswordResetRequest={onPasswordReset}
                onRequestOtp={onRequestOtp}
                isSeamlessLoginFailed={isSeamlessLoginFailed}
                wasSeamlessLoginShowedAndDenied={wasSeamlessLoginShownAndDenied}
                goBackToSeamlessLogin={goBackToSeamlessLogin}
                credentialsLoginAttemptCounter={credentialsLoginAttemptCounter}
                setCredentialsLoginAttemptCounter={setCredentialsLoginAttemptCounter}
              />
            );
          },
          REQUEST_OTP: () => {
            const { next, prev } = useLoginControls();

            const emailDetails = contactDetails?.find(
              (detail) => detail.type === ContactDetailType.email,
            );
            const phoneDetails = contactDetails?.find(
              (detail) => detail.type === ContactDetailType.phone,
            );

            const onSendOtpRequest = async (
              contactDetailType: ContactDetailType,
            ): Promise<void> => {
              const isSuccess = await onRequestOtpCode(contactDetailType);
              if (isSuccess) {
                return next(
                  contactDetailType === ContactDetailType.email
                    ? LoginStep.CHECK_YOUR_EMAIL
                    : LoginStep.CHECK_YOUR_PHONE,
                );
              }
              sendErrorPageAnalytic(ErrorReasonOptions.FAILED_TO_SEND_CODE);
              return next(LoginStep.OTP_FAILURE);
            };

            return (
              <RequestOtpPage
                userEmail={emailDetails?.hint}
                userPhoneNumber={phoneDetails?.hint}
                onBack={prev}
                isLoading={sendOtpLoading || isSendOtpLoading}
                onSendOtpRequest={onSendOtpRequest}
              />
            );
          },
          CHECK_YOUR_EMAIL: () => {
            const { prev, next } = useLoginControls();
            const { updateContext } = useLoginContext();
            const emailDetails = contactDetails?.find(
              (detail) => detail.type === ContactDetailType.email,
            );

            const onSuccess = ({ accessToken, refreshToken }: AccessToken) => {
              updateContext({ accessToken, refreshToken });
              next(LoginStep.SUCCESS_LOGIN);
            };

            const onFailure = () => {
              setOtpRequestCodeAttemptCounter(0);
              setOtpSendCodeAttemptCounter(0);
              setOtpSubmitCodeAttemptCounter(0);

              sendErrorPageAnalytic(ErrorReasonOptions.MAX_ATTEMPTS);
              next(LoginStep.OTP_FAILURE);
            };

            const onResendOtpCode = async () => {
              const isSuccess = await onRequestOtpCode(ContactDetailType.email);
              if (!isSuccess) onFailure();
            };

            return (
              <EnterOtpCode
                onBack={prev}
                destinationHint={emailDetails?.hint ?? ''}
                contactDetailType={ContactDetailType.email}
                organizationId={organizationId}
                ehrUser={linkedEhrUsername}
                ehrCredentials={ehrCredentials}
                onSuccess={onSuccess}
                onFailure={onFailure}
                onResendPassword={onResendOtpCode}
                otpRequestLoading={sendOtpLoading}
                otpSubmitCodeAttemptCounter={otpSubmitCodeAttemptCounter}
                setOtpSubmitCodeAttemptCounter={setOtpSubmitCodeAttemptCounter}
              />
            );
          },
          CHECK_YOUR_PHONE: () => {
            const { prev, next } = useLoginControls();
            const { updateContext } = useLoginContext();
            const phoneDetails = contactDetails?.find(
              (detail) => detail.type === ContactDetailType.phone,
            );

            const onSuccess = ({ accessToken, refreshToken }: AccessToken) => {
              updateContext({ accessToken, refreshToken });
              next(LoginStep.SUCCESS_LOGIN);
            };

            const onFailure = (error?) => {
              if (error) {
                return sendLoginFailure(error);
              }
              sendErrorPageAnalytic(ErrorReasonOptions.MAX_ATTEMPTS);
              next(LoginStep.OTP_FAILURE);
            };

            const onResendOtpCode = async () => {
              const isSuccess = await onRequestOtpCode(ContactDetailType.phone);
              if (!isSuccess) onFailure();
            };

            return (
              <EnterOtpCode
                onBack={prev}
                destinationHint={phoneDetails?.hint ?? ''}
                contactDetailType={ContactDetailType.phone}
                organizationId={organizationId}
                ehrUser={linkedEhrUsername}
                ehrCredentials={ehrCredentials}
                onSuccess={onSuccess}
                onFailure={onFailure}
                onResendPassword={onResendOtpCode}
                otpRequestLoading={sendOtpLoading}
                otpSubmitCodeAttemptCounter={otpSubmitCodeAttemptCounter}
                setOtpSubmitCodeAttemptCounter={setOtpSubmitCodeAttemptCounter}
              />
            );
          },
          OTP_FAILURE: () => {
            const { next } = useLoginControls();
            const onGoBack = () => next(LoginStep.OTP_LOGIN);

            return <ErrorPage onGoBack={onGoBack} />;
          },
        }}
      />
    </div>
  );
};

enum ErrorReasonOptions {
  MAX_ATTEMPTS = 'Max code attempts exceeded',
  FAILED_TO_SEND_CODE = 'Failed to send otp code',
}

const sendErrorPageAnalytic = (reason: string) => {
  analyticsClient.track(VimConnectOtpLoginAnalyticsEventTypes.VimConnectOtpErrorPageDisplayed, {
    reason,
  });
};
