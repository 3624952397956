import React, { FC, useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { BackButton } from '../../atomic/atoms/back-button';
import { EmailSentMessage } from './email-sent-message-new';
import { UserWithoutEmail, UserWithEmail } from './email-cases';
import { ContactDetail, ContactDetailType } from '../../types';
import { sendPasswordResetLinkMutation } from '../../logic/queries';
import { UserType } from '../../logic/types';
import {
  analyticsClient,
  VimConnectCredentialsLoginAnalyticsEventTypes,
} from '../../logic/analytics';
import { FeatureFlags } from '../../logic/feature-flags';
import { seamlessApiClient } from '../../api/seamlessApiClient';

export interface PasswordResetFormProps {
  exitForm: () => void;
  contactDetails?: ContactDetail[];
  username: string;
  organizationId: number;
}

export const PasswordResetForm: FC<PasswordResetFormProps> = ({
  exitForm,
  contactDetails,
  username,
  organizationId,
}) => {
  const [wasRestPasswordLinkCalled, setWasRestPasswordLinkCalled] = useState(false);

  const [sendPasswordReset, { called }] = useMutation(sendPasswordResetLinkMutation, {
    variables: {
      input: {
        username,
        organizationId,
        contactDetailType: ContactDetailType.email,
        userType: UserType.VimEhrUser,
      },
    },
    context: { uri: '/runtime/api/graphql' },
  });

  const userHasEmail = contactDetails?.find((detail) => detail.type === ContactDetailType.email);

  const onPasswordResetSubmit = useCallback(async () => {
    analyticsClient.track(
      VimConnectCredentialsLoginAnalyticsEventTypes.vimConnectCredentialsSendResetPasswordEmailButtonClicked,
      {},
    );

    const shouldUseSeamlessAuthApiForLoginPart2FF =
      await FeatureFlags.getShouldUseSeamlessAuthApiForLoginPart2({
        organizationIdString: organizationId?.toString(),
      });

    if (shouldUseSeamlessAuthApiForLoginPart2FF) {
      seamlessApiClient.postResetPasswordLink({
        username,
        organizationId,
        contactDetailType: ContactDetailType.email,
        userType: UserType.VimEhrUser,
      });

      setWasRestPasswordLinkCalled(true);
    } else {
      await sendPasswordReset();
    }
  }, [organizationId, sendPasswordReset, username]);

  return (
    <div>
      <form name="vim-ehr-login" autoComplete="off">
        <BackButton onBack={exitForm} />
        <div className="login-form-row">
          {called || wasRestPasswordLinkCalled ? (
            <EmailSentMessage />
          ) : (
            <>
              {userHasEmail ? (
                <UserWithEmail
                  email={userHasEmail.hint}
                  username={username}
                  onPasswordResetSubmit={onPasswordResetSubmit}
                />
              ) : (
                <UserWithoutEmail />
              )}
            </>
          )}
        </div>
      </form>
    </div>
  );
};
