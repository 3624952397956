import { AppEnv } from '../types/appEnv';

let appEnv: AppEnv;
export function getAppEnv(): AppEnv {
  if (!appEnv) {
    appEnv = (window as any).$vim_environment?.APP_ENV as AppEnv;
  }

  return appEnv;
}
