import { featureFlagsClient } from './featureFlags.client';
import { Team } from '@getvim/feature-flags';

export async function getShouldUseSeamlessAuthApiForLogin({
  organizationId,
}: {
  organizationId?: number;
}) {
  return await featureFlagsClient.getFlag({
    flagName: 'vc_login.shouldUseSeamlessAuthApiForLogin',
    defaultValue: false,
    flagContext: {
      organizationId_string: organizationId?.toString(),
    },
    team: Team.OMT,
  });
}

export async function getShouldUseSeamlessAuthApiForLoginPart2({
  organizationIdString,
}: {
  organizationIdString?: string;
}) {
  return await featureFlagsClient.getFlag({
    flagName: 'vc_login.shouldUseSeamlessAuthApiForLoginPart2',
    defaultValue: false,
    flagContext: {
      organizationId_string: organizationIdString,
    },
    team: Team.OMT,
  });
}
